<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-left-panel :data-item='dataItem'></project-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

                <Sidebar v-model:visible="visibleLeft" :baseZIndex="1000">
					<h3>Lokasyon</h3>

					<p class="text-lg">
                        Projenin hizmet verdiği lokasyon bilgilerini giriniz. Posta kodunu mahalle adı ile web üzerinden bulabilirsiniz.<br>
                        <br>
                
					</p>

				</Sidebar>

				</span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>
                <ProjectTabMenu :project-id='projectId'></ProjectTabMenu>
                <div class='col-12'>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Adres<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <InputText id="address" v-model='dataItem.address' type="text" autocomplete='off'/>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">Posta Kodu<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <InputText id="postalCode" v-model='dataItem.postalCode' type="text" maxlength='5' autocomplete='off'/>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">İl<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <Dropdown id="city" v-model="dataItem.cityId" :options="cityList" optionLabel="name" option-value='id' placeholder="Seçim Yapın"  @change="cityChanged"></Dropdown>
                        </div>
                    </div>

                    <div class="field grid mb-5" v-if='dataItem.cityId > 0'>
                        <label class="col-12 mb-2 md:col-2 md:mb-0">İlçe<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <Dropdown id="town" v-model="dataItem.townId" :options="townList" optionLabel="name" option-value='id' placeholder="Seçim Yapın"></Dropdown>
                        </div>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' class='mr-2 mb-2' @click='save'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import ProjectService from '../../services/projectService';
import AdminToast from '@/components/shared/toast/adminToast';
import moment from 'moment';
import AddressService from '../../services/addressService';
import ProjectLeftPanel from './projectLeftPanel';
import ProjectTabMenu from './projectTabMenu';
import { getProject } from '../common/commonFunctions';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';

export default {
    components: { ProjectTabMenu, ProjectLeftPanel, AdminToast },
    _projectService: null,
    _addressService: null,
    created() {
        this._addressService = new AddressService();
        this._projectService = new ProjectService();
        this.projectId = this.$route.params.projectId;
    },
    async mounted() {
        checkActiveProjectIdIsValid(this, this.projectId);
        this.dataItem = await getProject(this.projectId);
        await this.fillDropDowns(this.dataItem.cityId);
    },
    data() {
        return {
            projectId: 0,
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                startDate: '',
                endDate: '',
                address:"",
                postalCode:"",
                cityId:0,
                townId:0
            },
            cityList: [],
            townList:[],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async save() {

            if (this.validateForm()) {
                const updateResponse = await this._projectService.updateProjectLocation(this.projectId, this.dataItem);
                if (updateResponse.isSuccess) {
                    showSuccessMessage(this, "Lokasyon Güncelleme Başarılı");
                } else {
                    showErrorMessage(this, 'Lokasyon Güncellemede Sorun İle Karşılaşıldı');
                }
            }
        },

        validateForm() {
            if (this.dataItem.address == null || this.dataItem.address == '') {
                showValidationMessage(this, 'Adres Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.postalCode == null || this.dataItem.postalCode == '') {
                showValidationMessage(this, 'Posta Kodu Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.cityId == null || this.dataItem.cityId == '') {
                showValidationMessage(this, 'İl Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.townId == null || this.dataItem.townId == '') {
                showValidationMessage(this, 'İlçe Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },
        async getProject(projectId) {
            let getResponse = await this._projectService.getProjectById(projectId);
            if (getResponse.isSuccess) {
                this.dataItem.name = getResponse.data.name;
                this.dataItem.personName = getResponse.data.personName;
                this.dataItem.email = getResponse.data.email;
                this.dataItem.phone = getResponse.data.phone;
                this.dataItem.personPhone = getResponse.data.personPhone;
                this.dataItem.startDate = getResponse.data.startDate;
                this.dataItem.isActive = getResponse.data.isActive;
                this.dataItem.personEmail = getResponse.data.personEmail;
                this.dataItem.endDate = getResponse.data.endDate;
                this.dataItem.projectLogo = getResponse.data.projectLogo;
                if(this.dataItem.startDate != null && this.dataItem.startDate != "")
                {
                    this.dataItem.startDate = moment(this.dataItem.startDate).format('DD.MM.YYYY');
                }
                this.dataItem.cityName = getResponse.data.cityName;
                this.dataItem.townName = getResponse.data.townName;
                this.dataItem.cityId = getResponse.data.cityId;
                this.dataItem.townId = getResponse.data.townId;
                this.dataItem.address = getResponse.data.address;
                this.dataItem.postalCode = getResponse.data.postalCode;
            } else {
                showErrorMessage(this, getResponse.message);
            }
        },
        async fillDropDowns(cityId) {
            let cityResponse = await this._addressService.getCities(90);
            if(cityResponse.isSuccess)
            {
                this.cityList = cityResponse.data;
            }
            if(cityId != null && cityId >0)
            {
                let townResponse = await this._addressService.getTowns(cityId);
                if(townResponse.isSuccess)
                {
                    this.townList = townResponse.data;
                }
            }

        },
        async cityChanged(event) {
            let townResponse = await this._addressService.getTowns(event.value);
            if (townResponse.isSuccess) {
                this.townList = townResponse.data;
            }
        }
    },
};

</script>


<style scoped>

</style>
